<template>
  <div>
    <v-img src="/img/logo.png" alt="Logo" max-width="50%" class="mx-auto"/>

    <v-container>
    <p class="font-weight-black">
    FauxDIS is a collaborative DNA profile database modeled after the U.S. Combined DNA Index System, CODIS.<br>
      It is a searchable listing of the CODIS loci.</p>
    <br>
    <v-alert
        border="bottom"
        color="pink darken-1"
        dark
    >

    FauxDIS is available for use in exchange for the submission of novel profiles to the database.
    <br>For more information, access and to contribute, contact: FauxDIS@gmail.com
    </v-alert>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Home"
}
</script>

<style scoped>

</style>