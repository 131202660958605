<template>
  <div>

    <v-img src="/img/logo.png" alt="Logo" max-width="50%" class="mx-auto"/>

    <v-container>
      <p class="font-weight-black">
        FauxDIS is available for use by educators in exchange for the submission of novel profiles to the database. The
        long-term goal of this project is to establish a learning network by encouraging national and international
        collaboration. <br>
        Contact: FauxDIS@gmail.com
      </p>
      <p class="text-left">
        FauxDIS can be used as part of experiential learning exercises in which students apply the scientific method to
        solve mock crimes. Using FauxDIS, students have the opportunity to search DNA profiles generated from mock
        evidence against known profiles contained in the database. A discussion of strategies for using the database
        results in the calculation of genotype probabilities can be found in the below referenced open access
        publication.
      </p>
      <h3>Publications</h3>
      <p class="text-left">
        FauxDIS: <a href="https://jfse-ojs-tamu.tdl.org/jfse/index.php/jfse/article/view/22">A Searchable Forensic DNA
        Database to Support Experiential Learning.</a> Jacqueline Baranski, Karen Davalos-Romero, Melanie Blum, Nichole
        Burke, Ashley Foster, Ashley Hall. Journal of Forensic Science Education; 2 (1), <b>2020</b>.
      </p>
      <br>
      <h3>Technologies</h3>
      <p class="text-left">
      FauxDIS is developed by <a href="https://www.bjonnh.net">Jonathan Bisson</a> using <a href="https://www.kotlinlang.org">Kotlin</a>, <a href="https://spring.io/">Spring
      Boot</a>,
      <a href="https://vuejs.org/">Vue</a>, <a href="https://vuetifyjs.com/">Vuetify</a>,
      <a href="https://www.postgresql.org/">PostgreSQL</a> and a lot of other projects.<br>

        We would like to thank <a href="https://www.jetbrains.com/">JetBrains</a> for the IntelliJ Idea academic license.
      </p>
      <p>
        The code will be made available really soon.
      </p>


    </v-container>
  </div>
</template>

<script>
export default {
  name: "Home"
}
</script>

<style scoped>

</style>
